import { createRoot } from "react-dom/client";

import { onCLS, onLCP } from "web-vitals";
onLCP((metric) => console.log("LCP: ", { ...metric }), { reportAllChanges: true });
onCLS((metric) => console.log("CLS: ", { ...metric }), { reportAllChanges: true });

import App from "./App";

export default function renderApp() {
  const container = document.getElementById("app");
  const root = createRoot(container);
  root.render(<App />);
  // ReactDOM.render(<App />, document.getElementById("app"));
}

renderApp();
